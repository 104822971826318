<template>
  <div class="base">
    <div class="card-title">意见反馈</div>
    <div class="card">
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>反馈标题</span>
        </div>
        <input class="cell-content" placeholder="请输入反馈标题" v-model="title" maxlength="20" />
      </div>
      <div class="card-cell textarea-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>反馈内容</span>
        </div>
        <textarea
          class="cell-content"
          placeholder="请输入反馈内容"
          v-model="content"
          maxlength="300"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="card-title">反馈图片</div>
    <div class="card">
      <div class="card-cell imgs-cell">
        <div class="up-img-cell" v-for="(item, index) in fileList" :key="index">
          <img class="up-img" :src="imgUrl(item)" alt="" @click="showImg(item)" />
          <img class="remove-img" @click="onRemoveFile(index)" src="@/assets/img/enterprise/remove.png" alt="" />
        </div>
        <div class="up-img-cell" v-if="fileList.length <= 2">
          <upload-img @sendOrgImg="getOrgImg"></upload-img>
        </div>
      </div>
    </div>
    <div class="but-cell">
      <mt-button class="save-but" :loading="submiting" @click="onSubmit" type="primary">提交</mt-button>
    </div>
    <mt-popup v-model="showImgFlag">
      <img :src="tempImgUrl" class="big-img" />
    </mt-popup>
  </div>
</template>

<script>
import UploadImg from '@/components/UploadImg'
import { Toast, Indicator } from 'mint-ui'
import { addFeedback, upload } from '@/api/enterprise'

export default {
  components: { UploadImg },
  data() {
    return {
      submiting: true,
      fileList: [],
      upImgUrls: [],
      title: '',
      content: '',
      showImgFlag: false,
      tempImgUrl: ''
    }
  },
  created() {},
  methods: {
    onRemoveFile(index) {
      this.fileList.splice(index, 1)
    },
    async onSubmit() {
      if (!this.title) {
        Toast('请输入反馈标题')
        return
      }
      if (!this.content) {
        Toast('请输入反馈内容')
        return
      }
      Indicator.open()
      this.submiting = true
      await this.uploadImgs()
      const fd = new FormData()
      fd.append('title', this.title)
      fd.append('content', this.content)
      fd.append('image', this.upImgUrls.join(','))
      try {
        const __params = {}
        for (const [k, v] of fd.entries()) {
          __params[k] = v
        }
        await addFeedback(__params)
        Toast('提交成功')
        await this.$router.go(-1)
      } catch (error) {
        Toast('请求失败')
      } finally {
        this.submiting = false
        Indicator.close()
      }
    },
    getOrgImg(data) {
      this.fileList.push(data)
    },
    async uploadImgs() {
      const that = this
      for (let i = 0; i < this.fileList.length; i++) {
        try {
          const fd = new FormData()
          fd.append('file', this.fileList[i])
          const { success, message } = await upload(fd)
          if (success) {
            that.upImgUrls.push(message)
          }
        } catch (error) {
          Toast('图片' + (i + 1) + '上传失败')
        } finally {
          this.submiting = false
          Indicator.close()
        }
      }
    },
    imgUrl(item) {
      console.log(URL.createObjectURL(item))
      return URL.createObjectURL(item)
    },
    showImg(item) {
      // this.showImgFlag = true
      // this.tempImgUrl = item
    }
  }
}
</script>

<style lang="less" scoped>
input,
input:hover,
input:focus {
  border: none;
  text-align: right;
}

textarea,
textarea:hover,
textarea:focus {
  border: none;
}

.base {
  background: #f7f8fc;
  min-height: 100vh;
  font-weight: 'Source Han Sans CN';

  .card-title {
    font-weight: 400;
    font-size: 15px;
    color: #999999;
    padding: 0px 20px;
    height: 55px;
    line-height: 55px;
  }

  .card {
    background: #ffffff;
    padding: 0px 20px;
    box-shadow: 0px 0px 2px 0px rgba(23, 140, 249, 0.1);

    &-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f7f8fc;

      .cell-title {
        height: 50px;
        line-height: 50px;
      }

      .required {
        color: red;
      }

      .cell-content {
        width: 220px;
        font-size: 14px;
        line-height: 30px;
        text-align: right;
      }
    }

    .textarea-cell {
      flex-direction: column;
      align-items: flex-start;

      .cell-content {
        width: 315px;
        font-size: 14px;
        text-align: left;
        background: rgba(242, 242, 242, 0.8);
        border-radius: 10px;
        padding: 5px 10px;
        line-height: 35px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
      }
    }

    .imgs-cell-title {
      height: 50px;
      line-height: 50px;
      margin-bottom: -20px;

      .required {
        color: red;
      }
    }

    .imgs-cell {
      justify-content: flex-start;

      .up-img-cell {
        position: relative;

        .up-img {
          margin: 20px 17.5px 20px 0px;
          width: 100px;
          height: 100px;
        }

        .up-img:last-child {
          margin-right: 0px;
        }

        .remove-img {
          position: absolute;
          right: 6px;
          top: 8px;
          width: 25px;
          height: 25px;
        }

        .hidden-up-but {
          position: absolute;
          top: 20px;
          left: 0px;
          width: 100px;
          height: 100px;
          opacity: 0;
        }
      }
    }
  }

  .but-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    .save-but {
      width: 335px;
      background-color: #1073e3;
      margin: 20px 0;
    }
  }

  .big-img {
    width: 375px;
  }
}
</style>
